export const Servicios = [
    {
        id: 1,
        title: "Auditorías de Cíberseguridad",
        description: "Nuestros servicios de auditoría de ciberseguridad están diseñados para identificar, evaluar y mitigar posibles vulnerabilidades en los sistemas de su empresa. Como líderes en el sector, ofrecemos un enfoque integral que incluye análisis exhaustivos de su infraestructura digital, revisión de políticas de seguridad, y pruebas de penetración avanzadas. Nos aseguramos de que su organización cumpla con las normativas de seguridad más exigentes, proporcionando soluciones personalizadas para fortalecer la protección de sus datos y mantener la integridad de su entorno digital frente a amenazas cibernéticas emergentes.",
    },
    {
        id: 2,
        title: "Consultoría en Cibercrimen",
        description: "Nuestros servicios de consultoría en cibercrimen se especializan en la investigación y rastreo de delitos digitales ya cometidos. Nos enfocamos en desentrañar fraudes en la blockchain, rastrear estafas cibernéticas, y analizar casos de secuestro de información, pedofilia y acoso. Además, contamos con la experiencia y el conocimiento necesario para actuar como testigos expertos en procedimientos legales, proporcionando pruebas técnicas y testimonios especializados que refuerzan su caso. Como líderes en el sector, nos comprometemos a ofrecer soluciones precisas y confiables para esclarecer y combatir el cibercrimen.",
    },
    {
        id: 3,
        title: "Respuesta a Incidentes & Gestión de Riesgo",
        description: "Nuestros servicios de Respuesta a Incidentes y Gestión de Riesgo están diseñados para ayudar a su empresa a enfrentar y mitigar los impactos de ciberataques y violaciones de seguridad. Nos especializamos en la detección rápida de incidentes, la contención y recuperación efectiva, así como en la identificación y evaluación de riesgos potenciales en su infraestructura digital. Implementamos estrategias proactivas para fortalecer su postura de seguridad y minimizar la exposición a amenazas futuras. Como líderes en el sector, ofrecemos un enfoque integral que combina la respuesta inmediata con la gestión continua de riesgos, asegurando que su organización esté preparada para enfrentar cualquier desafío cibernético con confianza.",
    },
    {
        id: 4,
        title: "Equipo Forense",
        description: "Nuestro Equipo Forense se especializa en la investigación detallada y análisis técnico de incidentes de seguridad cibernética. Nos dedicamos a recolectar, preservar y examinar evidencia digital para esclarecer ataques, intrusiones, y cualquier tipo de actividad maliciosa en su entorno digital. Utilizando técnicas avanzadas y herramientas de última generación, nuestro equipo identifica la fuente del ataque, determina el alcance del daño, y ayuda a su empresa a recuperar la integridad de sus sistemas. Como líderes en el sector, ofrecemos informes exhaustivos y confiables que pueden ser utilizados en procesos legales, proporcionando claridad y soporte en la resolución de incidentes cibernéticos..",
    },
    {
        id: 5,
        title: "Eliminación de Información",
        description: "Nuestros servicios de Eliminación de Información se centran en la protección total de datos sensibles mediante la implementación de procesos seguros y efectivos para su eliminación. Ofrecemos la creación de políticas personalizadas que garantizan la correcta eliminación de información, tanto en formato digital como físico. Esto incluye la eliminación segura de datos en computadoras, dispositivos electrónicos, y servidores, así como la destrucción física de documentos, discos duros, y otros medios de almacenamiento. Nuestro enfoque garantiza que la información confidencial no pueda ser recuperada ni utilizada de manera indebida, cumpliendo con las normativas de seguridad y protección de datos. Como líderes en el sector, nos aseguramos de que su organización maneje la eliminación de datos de manera segura y conforme a los estándares más estrictos.",
    },
    {
        id: 6,
        title: "Sistemas Físicos",
        description: "Nuestros servicios en Sistemas de Seguridad Física están diseñados para proteger su infraestructura mediante la implementación y auditoría de soluciones avanzadas como cámaras de seguridad, lectores biométricos, y sistemas de control de acceso. Nos encargamos de la instalación y configuración precisa de estos sistemas, asegurando que funcionen de manera óptima para resguardar sus instalaciones. Además, realizamos auditorías exhaustivas de los sistemas de seguridad existentes, identificando posibles vulnerabilidades y optimizando su configuración para garantizar la máxima protección. Como líderes en el sector, ofrecemos soluciones integrales que combinan tecnología de punta con prácticas de seguridad rigurosas, proporcionando a su empresa un entorno seguro y controlado."
    },
    {
        id: 7,
        title: "Desarrollo Seguro",
        description: "Nuestros servicios de Desarrollo Seguro están enfocados en la creación de software robusto y resistente a ciberamenazas desde su fase inicial. Implementamos prácticas de seguridad en cada etapa del ciclo de desarrollo, garantizando que las aplicaciones sean seguras por diseño. Esto incluye la revisión de código, pruebas de penetración, y la integración de mecanismos de seguridad que protegen contra vulnerabilidades comunes como inyecciones SQL, Cross-site scripting, y más. Además, ofrecemos consultoría para fortalecer sus procesos de desarrollo actuales, asegurando que cumplan con las normativas de seguridad más estrictas. Como líderes en el sector, proporcionamos soluciones de desarrollo que no solo cumplen con las expectativas funcionales, sino que también ofrecen una defensa sólida contra cualquier amenaza cibernética."
    },
    {
        id: 8,
        title: "QA & Testing",
        description: "Nuestros servicios de QA & Testing están diseñados para asegurar la calidad y seguridad de su software antes de su lanzamiento. Realizamos pruebas exhaustivas que incluyen análisis funcionales, pruebas de carga, y verificación de seguridad para identificar y corregir errores, vulnerabilidades y debilidades en su aplicación. Nuestro enfoque integral garantiza que su software no solo cumpla con los requisitos funcionales, sino que también resista ciberataques y mantenga la integridad de los datos. Además, ofrecemos servicios de automatización de pruebas y consultoría para optimizar sus procesos de aseguramiento de calidad, alineándolos con las mejores prácticas de la industria. Como líderes en el sector, garantizamos que su software se despliegue con la máxima confianza, brindando una experiencia segura y sin fallos para sus usuarios."
    }
]