import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Theme from "./components/Theme";
import Loader from "./components/Loader";

const HomePage = lazy(() => import("./pages/Home"));

function App() {
    return (
        <Theme>
            <Navbar />
            <Routes>
                <Route path="/" element={<Suspense fallback={<Loader />}><HomePage /></Suspense>} />
            </Routes>
        </Theme>
    );
}

export default App;
