import React, { createContext, useState } from "react";
import { Servicios } from "./data/Services";

export const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
    const [selectedService, setSelectedService] = useState(Servicios[0]);

    return (
        <ServiceContext.Provider value={{ selectedService, setSelectedService }}>
            {children}
        </ServiceContext.Provider>
    );
}