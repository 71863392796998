import { useState, useEffect, useContext } from "react";
import { ServiceContext } from "../ServiceContext";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Servicios } from "../data/Services";
import logo from '../assets/Logo.png'
import logoNegro from "../assets/LogoNegro.png";
import { FaAngleDown } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const NavBar = styled.nav`
    width: 100vw;
    height: 80px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 0 200px;
    z-index: 10;
    transition: all 0.3s ease;

    @media (max-width: 1500px) {
        padding: 0 40px
    }

    @media (max-width: 735px) {
        padding: 0 20px;
    }

    .sidebar-animation {
        opacity: 1;
        transform: translateX(0);
    }

    &.navbar-scrolled { 
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
`;

const Logo = styled.img`
    width: 300px;
    height: auto;

    @media (max-width: 1144px) {
        width: 250px;
    }

    @media (max-width: 735px) {
        width: 200px;
    }
`

const NavItems = styled.section`
    display: block flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    gap: 30px;

    @media (max-width: 835px) {
        background: white;
        opacity: 0;
        transform: translateX(-100%);
        flex-direction: column;
        justify-content: left;
        position: absolute;
        left: 0;
        top: 80px;
        height: 100vh;
        width: 100vw;
        padding: 20px 40px;
        gap: 0;
        transition: all 0.3s ease;
    }

`;

const NavItem = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${props => props.isServicesOpen || props.isProductsOpen ? props.theme.colors.action : props.theme.colors.text};
    font-size: ${props => props.theme.fontSizes.medium};
    font-weight: ${props => props.theme.fontWeight.medium};
    font-family: ${props => props.theme.font};
    cursor: pointer;
    transition: all 0.3s ease;

    div {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &:hover {
        padding-bottom: 5px;
    }

    @media (max-width: 835px) {
        width: 100%;
        padding: 15px 1rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: auto;
        color: black;

        &:hover {
            padding-bottom: none;
            border-right: 2px solid ${props => props.theme.colors.action};
        }
    }

    &.navbar-scrolled {
        color: ${props => props.isServicesOpen || props.isProductsOpen ? props.theme.colors.primary : props.theme.colors.contrastText};
    }
`;

const StyledNavItem = styled(NavItem)`
    &:hover {
        border-bottom: 2px solid ${props => props.theme.colors.action};
        color: ${props => props.theme.colors.action};
    }

    @media (max-width: 835px) {
        &:hover {
            border-right: 2px solid ${props => props.theme.colors.action};
            border-bottom: none;
        }
    }

    &.navbar-scrolled {
        color: ${props => props.theme.colors.contrastText};

        &:hover {
            border-bottom: 2px solid ${props => props.theme.colors.primary};
            color: ${props => props.theme.colors.primary};
        }
    }
`

const SubMenu = styled(motion.div)`
    position: ${({ ismobile }) => ismobile ? 'relative' : 'absolute'}; 
    width: max-content;
    top: 100%;
    left: 0;
    color: ${props => props.theme.colors.contrastText};
    background-color: white;
    border-top: 5px solid ${props => props.theme.colors.action};	
    border-radius: 10px 10px 0 0 ;
    z-index: 1;
    margin-top: -5px;
    
    ul {
        list-style: none;
    }

    ul li button {
        padding: 15px 20px 15px 10px;
        font-weight: ${props => props.theme.fontWeight.regular};
        font-size: ${props => props.theme.fontSizes.small};
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover {
            color: ${props => props.theme.colors.secondary};
        }
        
        &:first-child {
            border-radius: 5px 5px 0 0 ;
        }
    }

    @media (max-width: 835px) {
        width: 100%;
        border: none;
        border-radius: none;
        margin-top: 2px;
        top: 0;
        left: 0;
    } 

    
`;

const ContactButton = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    height: 100%;
    font-size: ${props => props.theme.fontSizes.medium};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.colors.action};
    cursor: pointer;
    transition: all 0.1s;
    background-color: transparent;

    &:hover {
        border-bottom: 2px solid ${props => props.theme.colors.action};
    }

    @media (max-width: 835px) {
        width: 100%;
        height: 50px;
        padding: 15px 1rem;
        &:hover {
            border-bottom: none;
            border-right: 2px solid ${props => props.theme.colors.action};
        }
    }

    &.navbar-scrolled {
        color: ${props => props.theme.colors.primary};

        &:hover {
            border-bottom: 2px solid ${props => props.theme.colors.primary};
            color: ${props => props.theme.colors.primary};
        }
    }
`;

const HamburgerMenu = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    color: white;
    font-size: 30px;

    @media (max-width: 835px) {
        display: flex;
    }

    &.navbar-scrolled {
        color: black;
    }
`;

const Navbar = () => {
    const { setSelectedService } = useContext(ServiceContext);

    const handleScrollToService = (service) => {
        setSelectedService(service); 
        document.getElementById('servicios').scrollIntoView({ behavior: 'smooth' });
    };

    const handleScrollToProducts = () => {
        document.getElementById('productos').scrollIntoView({ behavior: 'smooth' });
    }

    const handleScrollToContact = () => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }

    const handleScrollToAbout = () => {
        document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
    }

    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [ismobile, setismobile] = useState(window.innerWidth <= 804);
    const [scrolled, setScrolled] = useState(false);

    const toggleNav = ()  => setIsNavOpen(!isNavOpen)

    useEffect(() => {
        const handleResize = () => {
            setismobile(window.innerWidth <= 834);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <NavBar className={scrolled ? 'navbar-scrolled' : ';'}>
                <Link to="/page1"><Logo src={scrolled ? logoNegro : logo} alt="Company Logo"></Logo></Link>
                <HamburgerMenu onClick={toggleNav} className={scrolled ? 'navbar-scrolled' : ';'} >
                    {isNavOpen ? (
                        <IoMdClose />) :(
                        <IoMenu />
                        )
                    }
                </HamburgerMenu>
                <NavItems  className={isNavOpen ? "sidebar-animation" : ""}>
                    <NavItem isServicesOpen={isServicesOpen} className={scrolled ? 'navbar-scrolled' : ';'}
                        onMouseEnter={() => setIsServicesOpen(true)}
                        onMouseLeave={() => setIsServicesOpen(false)}
                        onClick={() => setIsServicesOpen(!isServicesOpen)}
                    >
                        <div><p>Servicios</p> <FaAngleDown /></div>
                        <SubMenu ismobile={ismobile}
                            initial={{ opacity: 0, y: -10, display: 'none' }}
                            animate={{ opacity: isServicesOpen ? 1 : 0, y: isServicesOpen ? 0 : -10, display: isServicesOpen ? 'block' : 'none' }}
                            transition={{ duration: 0.5 }}
                            >
                            <ul>
                                {Servicios.map(service => (
                                    <li key={service.id}><button key={service.id} onClick={() => handleScrollToService(service)}>{service.title}</button></li>
                                ))}
                            </ul>
                        </SubMenu>
                    </NavItem>
                    <StyledNavItem onClick={handleScrollToProducts} className={scrolled ? 'navbar-scrolled' : ';'}>Productos</StyledNavItem>
                    <StyledNavItem onClick={handleScrollToAbout} className={scrolled ? 'navbar-scrolled' : ';'}>Nosotros</StyledNavItem>
                    <ContactButton onClick={handleScrollToContact} className={scrolled ? 'navbar-scrolled' : ';'}><FaRegEnvelope />Contacto</ContactButton>
                </NavItems>
        </NavBar>
    )
}

export default Navbar;