import { ThemeProvider } from "styled-components";

const theme = {
    colors: {
        primary: "#003F88",
        primaryLight: "#33659f",
        primaryDark: "#002c5f",
        secondary: "#00a2e3",
        secondaryLight: "#33b4e8",
        secondaryDark: "#00719e",
        action: "#FFBB00",
        text: "#ffffff",
        contrastText: "#1D2731",
    },
    font: "'Roboto', sans-serif",
    fontSizes: {
        small: "14px",
        medium: "18px",
        large: "24px",
        xlarge: "32px",
        xxlarge: "42px",
    },
    fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        extrabold: 800
    }
}

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
  
export default Theme;